import {Model} from 'P24C';
import {get} from '../../../config.js';

export default class extends Model {
    static fields = {
        id: {},

        imageUk: {},

        imageRu: {},

        description: {
            value: '',
        },

        url: {},

        urlUk: {},

        urlEn: {},

        videoUrl: {},

        videoOptions: {},

        videoHeight: {
            // default height of youtube video (517 width)
            value: '315px',
        },

        tpl: {},
    };

    shareFacebook(lang) {
        let url;

        if (lang === 'ua') {
            url =
                this.attributes.urlUk ||
                this.attributes.url ||
                this.attributes.videoUrl ||
                'https://privat24.ua/' + (this.attributes.imageUk || this.attributes.imageRu);
        } else if (lang === 'en') {
            url =
                this.attributes.urlEn ||
                this.attributes.url ||
                this.attributes.videoUrl ||
                'https://privat24.ua/' + this.attributes.imageRu;
        } else {
            url =
                this.attributes.url ||
                this.attributes.videoUrl ||
                'https://privat24.ua/' + this.attributes.imageRu;
        }

        return 'http://www.facebook.com/share.php?u=' + url;
    }

    shareTwitter(lang) {
        let url,
            text = '';

        if (lang === 'ua') {
            url =
                this.attributes.urlUk ||
                this.attributes.url ||
                this.attributes.videoUrl ||
                'https://privat24.ua/' + (this.attributes.imageUk || this.attributes.imageRu);
            text = '&text=' + (this.attributes.descriptionUk || this.attributes.description);
        } else if (lang === 'en') {
            url =
                this.attributes.urlEn ||
                this.attributes.url ||
                this.attributes.videoUrl ||
                'https://privat24.ua/' + this.attributes.imageRu;
            text = '&text=' + this.attributes.description;
        } else {
            url =
                this.attributes.url ||
                this.attributes.videoUrl ||
                'https://privat24.ua/' + this.attributes.imageRu;
            text = '&text=' + this.attributes.description;
        }

        return 'https://twitter.com/intent/tweet?url=' + url + text;
    }

    isVideo() {
        return !!this.attributes.videoUrl;
    }

    getVideoUrl() {
        return this.get('videoUrl') + this.get('videoOptions');
    }

    getBackgroundImg() {
        return get('LANG') === 'ua'
            ? this.get('imageUk') || this.get('imageRu')
            : this.get('imageRu');
    }

    getLink() {
        let lang = get('LANG'),
            link;

        if (lang === 'ua') {
            link = this.get('urlUk') || this.get('url');
        } else {
            link = this.get('url');
        }

        return link;
    }

    getTitle() {
        let lang = get('LANG');

        return lang === 'ua'
            ? this.get('descriptionUk') || this.get('description')
            : this.get('description');
    }
}
