import {Model, ICurrency, INumber, IDate} from 'P24C';
import parsers from '../parsers/exchangeRate.m.p';

export default class extends Model {
    static parsers = parsers;

    static idAttribute = 'currency';

    static fields = {
        currency: {
            fieldConstructor: ICurrency,
        },
        baseCurrency: {
            fieldConstructor: ICurrency,
        },
        buy: {
            value: 0,
            fieldConstructor: INumber,
        },
        sale: {
            value: 0,
            fieldConstructor: INumber,
        },
        date: {
            value: 'today',
            fieldConstructor: IDate,
        },
    };
}
