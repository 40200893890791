import React from 'react';
import {View, Preloader, iSessionStorage, l10n, iURL} from 'P24C';
import mainTpl from '../templates/main.jsx';
import errorTpl from '../templates/error.jsx';
import reactErrorTpl from '../templates/reactError';
import {set as _confSet, get} from '../../../config';
import defaultLocale from '../../../l10n/ru.json';

l10n.setDefaultLocale(defaultLocale);

const MIN_MOBILE = 0,
    MAX_MOBILE = 760, // 768 tablet S
    MIN_TABLET = 761,
    MAX_TABLET = 1270; // 1280 laptop S

const locale = {
    ru: () => import(/* webpackChunkName: "localeRU" */ '../../../l10n/ru.json'),
    ua: () => import(/* webpackChunkName: "localeUA" */ '../../../l10n/ua.json'),
    en: () => import(/* webpackChunkName: "localeEN" */ '../../../l10n/en.json'),
};

export default class extends View {
    uiStates = {
        ...this.uiStates,
        REACT_ERROR: 'reactError',
    };

    templates = {
        [this.uiStates.INITIAL]: (
            <div className="module-app">
                <Preloader className="center" />
            </div>
        ),
        [this.uiStates.ERROR]: errorTpl,
        [this.uiStates.STANDBY]: (
            <div className="module-app">
                <Preloader className="center" />
            </div>
        ),
        [this.uiStates.INTERACTIVE]: mainTpl,
        [this.uiStates.REACT_ERROR]: reactErrorTpl,
    };

    constructor(props) {
        super(props);

        this.state = {
            // current UI states
            ui: this.uiStates.INITIAL,
            fingerprint: null,
            ...this._getViewportType(),
        };
    }

    componentDidCatch(error, info) {
        this.setState({
            ui: this.uiStates.REACT_ERROR,
            error,
            info,
        });
    }

    componentWillMount() {
        this._getFingerprint();
    }

    async componentWillReady() {
        await this._getInitialData();
    }

    componentReady() {
        this._getLocation();
        this._handleDeepLink();
    }

    commonProps() {
        return {
            ...this.props,
            isMobile: this.state.isMobile,
            isTablet: this.state.isTablet,
            fingerprint: this.state.fingerprint,
        };
    }

    _handleWindowResize = () => {
        const {isMobile, isTablet} = this._getViewportType();

        if (isMobile !== this.state.isMobile || isTablet !== this.state.isTablet) {
            this.setState({isMobile, isTablet});
        }
    };

    _getViewportType() {
        const width = document.body.offsetWidth;

        return {
            isMobile: width >= MIN_MOBILE && width <= MAX_MOBILE,
            isTablet: width >= MIN_TABLET && width <= MAX_TABLET,
        };
    }

    async _getInitialData() {
        const {lang} = this.props;

        try {
            await this._loadLocale(lang);
            this._setPageTitle();
        } catch (error) {
            this.uiError();
            throw new Error(error);
        }
    }

    _getLocation() {
        if (window.navigator.geolocation) {
            // timeout for safari bug with repeat asking
            setTimeout(() => {
                window.navigator.geolocation.getCurrentPosition(position => {
                    _confSet('USER_LAT', position.coords.latitude);
                    _confSet('USER_LON', position.coords.longitude);
                });
            }, 0);
        }
    }

    _getFingerprint() {
        window.PBfingerprintCallback = Fingerprint => {
            Fingerprint.get().then(data => {
                _confSet('USER_FINGERPRINT', data.fingerprint);
                this.setState({fingerprint: data.fingerprint});
            });
        };

        const hcc = document.createElement('script'),
            s = document.getElementsByTagName('script')[0];
        hcc.src = get('FINGERPRINT_BASE');
        hcc.async = true;
        hcc.type = 'text/javascript';

        s.parentNode.insertBefore(hcc, s.nextSibling);
        return this;
    }

    // https://www.privat24.ua/rd/transfer_to_card?hash=rd/transfer_to_card/%7B%22from%22:%22%22,%22to%22:%224731%202191%200074%206308%22,%22ccy%22:%22UAH%22,%22amt%22:%22100%22%7D
    _handleDeepLink() {
        const service = this.props.match?.params?.service;
        const json = this.props.match?.params?.json;
        let payload,
            hash = iURL.get('hash');

        if (json) {
            payload = json;
        } else {
            try {
                hash = decodeURIComponent(hash);
            } catch (e) {
                hash = '';
            }

            if (hash) {
                hash = hash.split('/');
                payload = hash && hash[2];
            }
        }

        if (service) {
            window.location.href = `${get('P24_BASE')}/deep-link/${service}${
                payload ? `/${payload}` : ''
            }${window.location.search}`;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.lang && this.props.lang !== prevProps.lang) {
            this._handleChangeLang();
        }
    }

    _setPageTitle(title = this.props.pageTitle) {
        document.querySelector('head title').innerHTML = this.l10n(title);
    }

    async _handleChangeLang() {
        await this.uiStandBy();
        await this._loadLocale(this.props.lang);
        await this.uiInteractive();

        this._setPageTitle();

        if (this.loginWidget) {
            this.loginWidget.restart(this._getAuthorizationOptions());
        }
    }

    async _loadLocale(lang) {
        l10n.setLocale(await locale[lang]());
        iSessionStorage.set('lang', lang);
        _confSet('LANG', lang);
    }

    static displayName = 'Application';
}
