import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {UserAgent} from 'P24C';

export default class extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {type} = this.props;
        let init;

        switch (type) {
            case 'sender-widget':
                init = this._initSenderWidget;
                break;
            case 'site-heart-chat':
                init = this._initSiteHeartChat;
                break;
            default:
                init = this._initLiqpayChat;
                break;
        }

        if (typeof init === 'function') {
            init();
        }

        return this;
    }

    _initSenderWidget = () => {
        window.senderCallback = () => {
            window.SenderWidget.init(this._getSenderWidgetOptions());
        };

        if (!window.SenderWidget) {
            (function(d, s, id) {
                let js,
                    fjs = d.getElementsByTagName(s)[0];
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://widget.sender.mobi/build/init.js';
                fjs.parentNode.insertBefore(js, fjs, 'sender-widget');
            })(document, 'script');
        } else {
            window.SenderWidget.restart({
                lang: this.props.lang,
            });
        }
    };

    _getSenderWidgetOptions = () => {
        return {
            companyId: 'i68481723518',
            resizable: true,
            height: 650,
            width: 400,
            lang: this.props.lang,
        };
    };

    _initLiqpayChat = () => {
        window.channelsCB = () => {
            ChannelsWidget.init({
                companyId: 'i14778026796',
                lang: this.props.lang,
            });
        };

        let head = document.getElementsByTagName('head')[0],
            script = document.createElement('script'),
            previousWidget = document.getElementById('ch-widget-loader');

        if (previousWidget) {
            previousWidget.remove();
        }

        script.setAttribute('src', 'https://w.liqpay.ua/chat');
        script.setAttribute('id', 'ch-widget-loader');
        script.setAttribute('crossorigin', 'anonymous');

        head.insertBefore(script, head.firstChild);
    };

    _initSiteHeartChat = () => {
        window.document.querySelector('#sh_button').remove();

        window._shcp = [];
        window._shcp.push(this._getSiteHeartChatOptions());

        if (UserAgent.browser.isMobileDevice) {
            window._shcp[0].side = 'bottom';
            window._shcp[0].position = 'right';
        }

        let hcc = document.createElement('script');
        hcc.type = 'text/javascript';
        hcc.async = true;
        hcc.src =
            ('https:' === document.location.protocol ? 'https' : 'http') +
            '://widget.siteheart.com/apps/js/sh.js';
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hcc, s.nextSibling);

        return this;
    };

    _getSiteHeartChatOptions = () => {
        return {
            widget_id: 698102,
            widget: 'Chat',
            side: 'right',
            position: 'center',
            template: 'green',
            language: this.props.lang,
        };
    };

    render() {
        return null;
    }

    static propTypes = {
        type: PropTypes.string,
        lang: PropTypes.string,
    };

    static displayName = 'Help';
}
