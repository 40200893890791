import React from 'react';
import ExchangeRate from '../../ExchangeRate/views/main.c';
import Languages from '../templates/l10n';
import logoImg from '../img/logo-privat.svg';
import '../less/index.less';

export default function template() {
    return (
        <div className="header-module">
            <div className="title">
                <img src={logoImg} />
                <h1>{this.l10n('HEADER_TITLE')}</h1>
            </div>

            <div>
                <Languages {...this.props} changeLanguage={this.changeLanguage.bind(this)} />
                <ExchangeRate {...this.props} />
            </div>
        </div>
    );
}
