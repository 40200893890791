import React from 'react';
import '../less/slide.less';
import {l10n} from 'P24C';

export default function template(props) {
    let slide = null,
        share = null,
        model = props.model,
        link = model.getLink(),
        title = model.getTitle(),
        slideTitle,
        videoHeight = model.get('videoHeight');

    if (model.isVideo()) {
        if (link) {
            let description = l10n.translate('NEWS_SLIDER_NEWS_TEXT');

            if (title) {
                description = `${l10n.translate(
                    'NEWS_SLIDER_NEWS_TEXT_2',
                )} «${title}» ${l10n.translate('NEWS_SLIDER_ON_SITE')}`;
            }

            slideTitle = (
                <div className="module-news-slide-title">
                    {description}&nbsp;
                    <a rel="noopener noreferrer" target="_blank" href={link}>
                        Privatbank.ua
                    </a>
                </div>
            );
        } else if (title) {
            slideTitle = <div className="module-news-slide-title">{title}</div>;
        }

        slide = (
            <div>
                <div className="module-news-slide-frame" style={{height: videoHeight}}>
                    <iframe
                        src={model.getVideoUrl()}
                        allowFullScreen={true}
                        frameBorder="0"
                        width="570"
                        height={videoHeight}
                    />
                </div>
                {slideTitle}
            </div>
        );
    } else {
        share = (
            <div className="module-news-slide-share">
                <div
                    className="module-news-slide-share-img module-news-slide-share-facebook"
                    onClick={e => props.onShare('fb', model)}
                    title="Facebook"
                />
                <div
                    className="module-news-slide-share-img module-news-slide-share-twitter"
                    onClick={e => props.onShare('tw', model)}
                    title="Twitter"
                />
                <div
                    className="module-news-slide-share-img module-news-slide-share-inst"
                    onClick={e => props.onShare('inst', model)}
                    title="Instagram"
                />
            </div>
        );

        slide = (
            <a
                className="module-news-slide-img"
                href={link}
                style={{background: `url(${model.getBackgroundImg()})`}}
                rel="noopener noreferrer"
                target="_blank"
            />
        );
    }

    return (
        <div className="module-news-slide" key={model.id}>
            {share}
            {slide}
        </div>
    );
}
