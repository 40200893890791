import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import Application from './components/Application';
import ThemeProvider from '@p24/web-components/components/themeProvider';

import {createStore, combineReducers} from 'redux';
import {Provider, connect} from 'react-redux';

import reducers from './reducers';
import theme from '@p24/web-components/themes/classic';
import {Switch, Route, Router} from 'react-router';
import {createBrowserHistory} from 'history';

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(combineReducers(reducers));
const onRouteChange = state => state;
const ApplicationRoot = connect(onRouteChange)(Application);
const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <Switch>
                    <Route exact path="/(rd|rd2)/:service/:json?" component={ApplicationRoot} />
                    <Route component={ApplicationRoot} />
                </Switch>
            </Router>
        </ThemeProvider>
    </Provider>,
    document.getElementById('app'),
);
