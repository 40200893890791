import React from 'react';
import style from '../less/index.less';

export default function template() {
    return (
        <div className="static-content">
            <div className="static-features">
                <div className="feature">
                    <div className="icon statements" />
                    <div className="title">{this.l10n('STATIC_INFO_STATEMENTS_TITLE')}</div>
                    <div>{this.l10n('STATIC_INFO_STATEMENTS')}.</div>
                </div>
                <div className="feature">
                    <div className="icon mobile" />
                    <div className="title">{this.l10n('STATIC_INFO_MOBILE_PAYMENTS_TITLE')}</div>
                    <div>{this.l10n('STATIC_INFO_MOBILE_PAYMENTS')}.</div>
                </div>
                <div className="feature">
                    <div className="icon communal" />
                    <div className="title">{this.l10n('STATIC_INFO_COMMUNAL_PAYMENTS_TITLE')}</div>
                    <div>{this.l10n('STATIC_INFO_COMMUNAL_PAYMENTS')}.</div>
                </div>
                <div className="feature">
                    <div className="icon transfers" />
                    <div className="title">{this.l10n('STATIC_INFO_TRANSFERS_TITLE')}</div>
                    <div>{this.l10n('STATIC_INFO_TRANSFERS')}.</div>
                </div>
                <div className="feature">
                    <div className="icon deposits" />
                    <div className="title">{this.l10n('STATIC_INFO_DEPOSITS_TITLE')}</div>
                    <div>{this.l10n('STATIC_INFO_DEPOSITS')}.</div>
                </div>
            </div>
        </div>
    );
}
