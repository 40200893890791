import React from 'react';
import {ErrorComponent, Button} from 'P24C';

export default function template() {
    return (
        <div className="module-app">
            <ErrorComponent type="global">
                <div>{this.state.error}</div>
                <div>{this.state.info}</div>
            </ErrorComponent>
            <br />
            <div className="text-center">
                <Button onClick={window.location.reload}>{this.l10n('Обновить')}</Button>
            </div>
        </div>
    );
}
