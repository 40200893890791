import React from 'react';
import Footer from '../../Footer/views/main.c';
import StaticInfo from '../../StaticInfo/views/main.c';
import NewsSlider from '../../NewsSlider/views/main.c';
import Header from '../../Header/views/main.c';
import Authorization from '../../Authorization';
import Help from '../../Help';
import '../less/index.less';
import '../less/content.less';
import EventListener from 'react-event-listener';

export default function template() {
    const {isMobile} = this.state,
        props = this.commonProps();
    let content = null;

    if (!isMobile) {
        content = (
            <div className="content-container">
                <StaticInfo {...props} />
                <div className="news-slider">
                    <NewsSlider {...props} />
                </div>
            </div>
        );
    }

    return (
        <div className="module-app">
            <EventListener target={window} onResize={this._handleWindowResize} />
            <Header {...props} />
            <Help {...props} />
            <section className="module-content">
                {content}
                <div className="content-action">
                    <Authorization {...props} />
                </div>
            </section>
            <Footer {...props} />
        </div>
    );
}
