import {View} from 'P24C';
import React from 'react';
import mainTpl from '../templates/main';
import Collection from '../collections/news.cll';

export default class extends View {
    templates = {
        [this.uiStates.INTERACTIVE]: mainTpl,
        [this.uiStates.ERROR]: <div />,
        [this.uiStates.INITIAL]: <div />,
        [this.uiStates.STANDBY]: <div />,
    };

    fetchBeforeRender = ['collection'];

    constructor(props) {
        super(props);

        this.state = {
            ui: this.uiStates.INITIAL,
        };

        this.collection = new Collection();

        return this;
    }

    share(type, model) {
        let link;

        if (type === 'inst') {
            return window.open('https://instagram.com/hamletinaction/');
        }

        link =
            type === 'fb'
                ? model.shareFacebook(this.props.lang)
                : model.shareTwitter(this.props.lang);
        return window.open(link, 'none', 'width=500,height=500');
    }

    static displayName = 'NewsSlider';
}
