import {get} from '../../../config';

export default {
    read: function() {
        return {
            url: get('NEWS_URL'),
            type: 'GET',
            dataType: 'json',
        };
    },
};
