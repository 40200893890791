import React from 'react';
import {Skeleton, Control} from 'P24C';
import '../less/skeleton.less';

export default function SkeletonMock() {
    return (
        <div className="skeleton">
            <Control>
                <Skeleton>
                    <h2 className="heading">&nbsp;</h2>
                </Skeleton>
            </Control>

            <Control>
                <Skeleton>
                    <div className="input">&nbsp;</div>
                </Skeleton>
            </Control>

            <div className="button-wrapper">
                <Skeleton>
                    <div className="button">&nbsp;</div>
                </Skeleton>
            </div>

            <div className="qr-wrapper">
                <Skeleton>
                    <div className="qr">&nbsp;</div>
                </Skeleton>
            </div>

            <Control>
                <Skeleton>
                    <div className="download-hint">&nbsp;</div>
                </Skeleton>
            </Control>

            <div className="download">
                <Skeleton>
                    <div className="download-item">&nbsp;</div>
                </Skeleton>
                <Skeleton>
                    <div className="download-item">&nbsp;</div>
                </Skeleton>
            </div>
        </div>
    );
}
