import {Collection} from 'P24C';
import Model from '../models/exchangeRate.m';
import queryParams from '../queries/exchangeRate.cll.q';
import parsers from '../parsers/exchangeRate.cll.p';

export default class extends Collection {
    static protocol = null;

    static model = Model;

    static queryParams = queryParams;

    static parsers = parsers;
}
