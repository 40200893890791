import React from 'react';
import {View, iURL} from 'P24C';
import _forEach from 'lodash.foreach';
import PropTypes from 'prop-types';
import mainTpl from '../templates/main';
import {get} from '../../../config';

export default class extends View {
    templates = {
        [this.uiStates.INITIAL]: <div />,
        [this.uiStates.STANDBY]: mainTpl,
        [this.uiStates.INTERACTIVE]: mainTpl,
    };

    constructor(props) {
        super(props);

        this.state = {
            ui: this.uiStates.INITIAL,
        };
    }

    componentReady() {
        this.uiStandBy().then(() => this._init());
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.fingerprint &&
            this.props.fingerprint !== prevProps.fingerprint &&
            window.p24LoginWidget
        ) {
            window.p24LoginWidget.update({fingerprint: this.props.fingerprint});
        }
    }

    componentWillUnmount() {
        window.p24LoginWidget && window.p24LoginWidget.destroy();
    }

    _init = () => {
        if (!window.p24LoginWidget) {
            window.p24LoginWidgetCallback = widget => {
                widget.init(this._getAuthorizationOptions());
            };

            const script = document.createElement('script');

            script.src = get('AUTHORIZATION_BASE');
            script.async = true;
            script.type = 'text/javascript';

            document.head.appendChild(script);
        } else {
            this.uiInteractive().then(() =>
                window.p24LoginWidget.restart(this._getAuthorizationOptions()),
            );
        }
    };

    _getAuthorizationOptions = () => {
        return {
            container: this.authorizationContainer,
            lang: this.props.lang,
            onLoad: () => this.uiInteractive(),
            onSuccess: this.onSuccess,
            onCancel: () => {},
            // colorScheme: 'classic',
            colorScheme: iURL.get('theme') || 'classic',
            urlParams: iURL.getSearchParams(),
            fingerprint: this.props.fingerprint,
            latitude: get('USER_LAT'),
            longitude: get('USER_LON'),
            skeleton: {
                phoneHeight: 74,
            },
        };
    };

    _authorizationRef = el => {
        this.authorizationContainer = el;
    };

    onSuccess = (data = {}) => {
        let executor;

        switch (data.cmd) {
            case 'redirect':
                executor = this._authorize;
                break;
            case 'redirect_get':
                executor = this._redirectGet;
                break;
            case 'redirect_post':
                executor = this._redirectPost;
                break;
            default:
                executor = this._authorize;
        }

        if (typeof executor === 'function') {
            executor.call(this, data);
        }

        if (typeof this.props.onSuccess === 'function') {
            this.props.onSuccess();
        }
    };

    _authorize = (data = {}) => {
        if (data.redirect_url) {
            window.location.href = data.redirect_url;
        }
    };

    _redirectGet = (data = {}) => {
        if (data.redirect_url) {
            window.location.href = data.redirect_url;
        }
    };

    _redirectPost = (data = {}) => {
        const form = window.document.createElement('form');

        form.setAttribute('action', data.redirect_url);
        form.setAttribute('method', 'post');

        if (data.params) {
            _forEach(data.params, (value, name) => {
                const input = window.document.createElement('input');
                input.setAttribute('name', name);
                input.setAttribute('value', value);
                form.appendChild(input);
            });
        }

        window.document.querySelector('body').appendChild(form);
        form.submit();
    };

    static propTypes = {
        onSuccess: PropTypes.func,
        onCancel: PropTypes.func,
        lang: PropTypes.string,
    };

    static displayName = 'Authorization';
}
