exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../img/cards.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../img/top-up.svg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../img/calendar.svg"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../img/transfer.svg"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("../img/deposite.svg"));

// Module
exports.push([module.id, ".static-content {\n  margin-bottom: 28px;\n  box-sizing: border-box;\n}\n.static-features {\n  font-size: 14px;\n}\n.static-features .feature {\n  margin-top: 24px;\n  zoom: 1;\n}\n.static-features .feature:first-child {\n  margin-top: 0;\n}\n.static-features .feature:after {\n  clear: both;\n  content: '';\n  display: block;\n  height: 0;\n}\n.static-features .feature .icon {\n  float: left;\n  width: 32px;\n  height: 32px;\n  background: no-repeat;\n  margin-right: 8px;\n  margin-top: 8px;\n  background-size: contain;\n}\n.static-features .feature .icon.statements {\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n}\n.static-features .feature .icon.mobile {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n}\n.static-features .feature .icon.communal {\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n.static-features .feature .icon.transfers {\n  background-image: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n.static-features .feature .icon.deposits {\n  background-image: url(" + ___CSS_LOADER_URL___4___ + ");\n}\n.static-features .feature .title {\n  font-weight: bold;\n  font-size: 16px;\n  color: #212121;\n}\n", ""]);

