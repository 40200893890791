import React from 'react';
import {Slider} from 'P24C';
import Slide from '../templates/slide';
import '../less/index.less';

export default function template() {
    let slides = [],
        slider = null;

    if (this.collection.length) {
        slides = this.collection.models.map(model => {
            return <Slide model={model} key={model.id} onShare={this.share.bind(this)} />;
        });
    }

    if (slides.length) {
        slider = (
            <Slider showNavigation showCounter showBullets={false}>
                {slides}
            </Slider>
        );
    }

    return (
        <div className="module-news">
            <div className="slider">{slider}</div>
        </div>
    );
}
