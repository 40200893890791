import {View} from 'P24C';
import mainTpl from '../templates/main';
import {connect} from 'react-redux';
import actionCreators from '../../../actions';

class Main extends View {
    template = mainTpl;

    changeLanguage(lang, event = {}) {
        if (event.nativeEvent) {
            event.preventDefault();
        }

        this.props.dispatch(actionCreators.lang(lang));
    }

    static displayName = 'Header';
}

const onStateChange = state => {
    return {
        lang: state.lang,
    };
};

export default connect(onStateChange)(Main);
