export default {
    read(response) {
        return {
            currency: response.ccy,
            baseCurrency: response.base_ccy,
            buy: response.buy,
            sale: response.sale,
            date: 'now',
        };
    },
};
