import {Collection} from 'P24C';
import Model from '../models/news.m';
import queryParams from '../queries/main.cll.q';

export default class extends Collection {
    static protocol = null;

    static model = Model;

    static queryParams = queryParams;
}
