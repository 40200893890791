export default {
    // TEXT COLOR

    // Основной фирменный цвет
    tAccent: '#8BC34A',
    // Темное состояние основного цвета для hover эффектов на кнопках, Цвет текстовых ссылок и выделенного текста
    tAccentDark: '#69af25',
    // Светлое состояние основного цвета для pass эффектов на кнопках. Hover цвет для текстовых ссылок
    tAccentLight: '#aee571',

    // Самый темный текст
    tPrimaryDark: '#000000',
    // // Основной текст
    tPrimary: '#212121',
    // Подсказка
    tHint: '#999999',
    // Цвет на темном фоне
    tInversion: '#FFF',
    // Второстепенный текст 0.54
    tSecondary: '#757575',
    // 0.38
    tSecondaryLight: '#9e9e9e',
    // Цвет предупреждений и статусов в обработке
    tWarning: '#f5a623',
    // Цвет ошибок и задолженности
    tError: '#ff635f',

    // Основной фирменный цвет
    elAccent: '#8BC34A',
    // Темное состояние основного цвета для hover эффектов на кнопках, Цвет текстовых ссылок и выделенного текста
    elAccentDark: '#69af25',
    // Светлое состояние основного цвета для pass эффектов на кнопках. Hover цвет для текстовых ссылок
    elAccentLight: '#aee571',

    // Подсказка
    elHint: '#999999',
    // Цвет на темном фоне
    elPrimary: '#FFF',
    // Второстепенный текст // 0.54
    elSecondary: '#757575',
    // Цвет предупреждений и статусов в обработке
    elWarning: '#f5a623',
    // Цвет ошибок и задолженности
    elError: '#ff635f',
    // фон ошибки
    elErrorLight: '#ffe8e7',
    // Рамка, Разделитель, Дополнительные кнопки
    elDivider: '#e0e0e0',
    elSecondaryLight: 'rgba(0, 0, 0, 0.08)',
    elSecondaryLightest: 'rgba(0, 0, 0, 0.04)',

    // тени
    elShadowMain: 'rgba(0, 0, 0, 0.24)',
    elShadowLight: 'rgba(0, 0, 0, 0.12)',

    // text
    fontFamily: 'Open Sans',

    // font weight
    twNormal: 400,
    // light
    twLight: 300,
    // bold
    twBold: 700,
    // semi-bold
    twSemi: 600,

    tsXXSmall: '10px',
    tsXSmall: '12px',
    tsSmall: '14px',
    tsNormal: '16px',
    tsLarge: '20px',
    tsXLarge: '22px',
    tsXXLarge: '24px',
    tsXXXLarge: '36px',

    tlXXSmall: '10px',
    tlXSmall: '20px',
    tlSmall: '22px',
    tlNormal: '24px',
    tlLarge: '28px',
    tlXLarge: '30px',
    tlXXLarge: '32px',
    tlXXXLarge: '44px',

    // 1280 - 16px scroll
    minWidth: '1264px',
    // 692
    minHeight: '692px',

    // gutter width
    gutter: 8,

    transitionDuration: '450ms',
    transitionDurationNumber: 450,

    transitionFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',

    transitionDelay: '0ms',

    mainTransition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',

    zMaximal: 1001,
    zOverlay: 999,

    desktopWidth: 1264,
    tabletWidth: 1025,
    mobileWidth: 380,
};
