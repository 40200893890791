import React from 'react';
import {ErrorComponent, Button} from 'P24C';
import style from '../less/index.less';

export default function template() {
    return (
        <div className="module-app">
            <ErrorComponent type="global">
                <div>{this.l10n('Не удалось загрузить интерфейс')}</div>
                <div className="description">{this.l10n('Пожалуйста, обновите страницу')}</div>
            </ErrorComponent>
            <br />
            <div className="text-center">
                <Button onClick={() => window.location.reload()}>{this.l10n('Обновить')}</Button>
            </div>
        </div>
    );
}
