import React from 'react';
import style from '../less/index.less';

export default function template() {
    let content = null;

    if (this.collection.length) {
        content = this.collection.models.map(model => {
            let rateA = null,
                rateB = null;

            if (model.get('currency').is('EUR')) {
                rateA = model.get('buy').toString(null, null, null, 2);
                rateB = model.get('sale').toString(null, null, null, 2);
            } else {
                rateA = model.get('buy').toString(null, null, null, 3);
                rateB = model.get('sale').toString(null, null, null, 3);
            }

            return (
                <div key={model.id} className="section">
                    <div className="section-content type">{model.get('currency').toString()}:</div>
                    <div className="section-content rate">
                        {rateA}&nbsp;/&nbsp;{rateB}
                    </div>
                </div>
            );
        });
    }

    return <div className="exchange-rate-module">{content}</div>;
}
