import React from 'react';
import style from '../less/l10n.less';

export default function template(props) {
    let ruLanguage = null,
        enLanguage = null,
        uaLanguage = null,
        lang = props.lang;

    if (lang === 'en') {
        enLanguage = <span title="English">EN</span>;
    } else {
        enLanguage = (
            <a href="/" onClick={e => props.changeLanguage('en', e)} title="English">
                EN
            </a>
        );
    }

    if (lang === 'ru') {
        ruLanguage = <span title="Русский">RU</span>;
    } else {
        ruLanguage = (
            <a href="/" onClick={e => props.changeLanguage('ru', e)} title="Русский">
                RU
            </a>
        );
    }

    if (lang === 'ua') {
        uaLanguage = <span title="Українська">UK</span>;
    } else {
        uaLanguage = (
            <a href="/" onClick={e => props.changeLanguage('ua', e)} title="Українська">
                UK
            </a>
        );
    }

    return (
        <div className="languages">
            {enLanguage}
            {ruLanguage}
            {uaLanguage}
        </div>
    );
}
