export default {
    read() {
        return {
            url: 'https://api.privatbank.ua/p24api/pubinfo?jsonp=success&exchange=&coursid=11',
            type: 'GET',
            timeout: 5000,
            dataType: 'jsonp',
            cache: false,
            callbackParamName: 'success',
        };
    },
};
