import {View, Collection} from 'P24C';
import mainTpl from '../templates/main';

export default class extends View {
    template = mainTpl;

    constructor(props) {
        super(props);

        this.state = {
            collection: new Collection([
                {id: 'ua', name: 'Українська'},
                {id: 'ru', name: 'Русский'},
                {id: 'en', name: 'English'},
            ]),
        };
    }

    static displayName = 'Footer';
}
