import React from 'react';
import {IDate, Link} from '@p24/web-components';
import '../less/index.less';
import guideline from '../../../guideline';
import {get} from '../../../config';

export default function template() {
    return (
        <footer className="module-footer">
            <div>
                <div className="phone">
                    <div className="phone-icon" />
                    <span>3700</span>
                </div>
                <div className="menu">
                    <Link
                        className="menu-item"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`https://privatbank.ua/${
                            get('LANG') === 'ru' ? 'ru/' : ''
                        }perevod-na-kartu-schet-v-privatbanke`}
                    >
                        {this.l10n('FOOTER_REGULATIONS_TARIFFS')}
                    </Link>
                    <Link
                        href="https://api.privatbank.ua/"
                        className="menu-item"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        API
                    </Link>
                    <Link
                        className="menu-item"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="http://privatbank.ua/apps/"
                    >
                        {this.l10n('FOOTER_MOBILE_APPLICATIONS')}
                    </Link>
                    <Link
                        href="http://privatbank.ua/safeness/"
                        className="menu-item"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {this.l10n('FOOTER_SAFETY_REGULATIONS')}
                    </Link>
                    <Link
                        className="menu-item"
                        href={`${get('P24_BASE')}/static/docs/personal_data_security.pdf`}
                        target="_blank"
                    >
                        {this.l10n('FOOTER_PERSONAL')}
                    </Link>
                </div>
                <div className="download-wrapper">
                    <Link
                        href="https://apps.apple.com/us/app/privat24-opened-to-everyone/id1333984846?l=ru&ls=1"
                        className="margin-right-2"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <span className="download app-store" />
                    </Link>
                    <Link
                        href="https://play.google.com/store/apps/details?id=ua.privatbank.ap24"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <span className="download google-play" />
                    </Link>
                </div>
            </div>
            <div>
                <div className="copyright">
                    <div className="copyright-icon" />
                    <div>
                        <div>
                            <span>©&nbsp;{new IDate().toString('yyyy')}</span>&nbsp;
                            <Link
                                rel="noopener noreferrer"
                                target="_blank"
                                className="link"
                                href="http://privatbank.ua/"
                                style={{fontSize: guideline.tsSmall}}
                            >
                                {this.l10n('FOOTER_BANK')}
                            </Link>
                        </div>
                        <div>{this.l10n('FOOTER_LICENSE')}</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
