import actionTypes from '../actions/actionTypes';
import {iSessionStorage} from 'P24C';

const DEFAULT_LANG = iSessionStorage.get('lang') || 'ua';

export default function(state = DEFAULT_LANG, action = {}) {
    if (action.type === actionTypes.CHANGE_LANG) {
        return action.value;
    } else {
        return state;
    }
}
