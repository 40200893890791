import React from 'react';
import '../less/index.less';
import Skeleton from '../views/skeleton.c';

export default function template() {
    return (
        <div className="module-authorization">
            {this.isUiStandBy() ? <Skeleton /> : null}
            <div
                className={`module-authorization-frame ${this.isUiStandBy() ? 'hidden' : ''}`}
                ref={this._authorizationRef}
                data-qa-node="login-frame"
            />
        </div>
    );
}
