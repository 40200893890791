import {View} from 'P24C';
import React from 'react';
import mainTpl from '../templates/main';
import Collection from '../collections/exchangeRate.cll';

export default class extends View {
    templates = {
        [this.uiStates.INTERACTIVE]: mainTpl,
        [this.uiStates.ERROR]: <div />,
        [this.uiStates.INITIAL]: <div />,
        [this.uiStates.STANDBY]: <div />,
    };

    fetchBeforeRender = ['collection'];

    constructor(props) {
        super(props);

        this.state = {
            ui: this.uiStates.INITIAL,
        };

        this.collection = new Collection();

        return this;
    }

    static displayName = 'ExchangeRate';
}
