import actionTypes from './actionTypes';

export default {
    lang: function(value) {
        return {
            type: actionTypes.CHANGE_LANG,
            value,
        };
    },
    pageTitle: function(value) {
        return {
            type: actionTypes.CHANGE_PAGE_TITLE,
            value,
        };
    },
};
